import React from 'react';
import Question from './Question';
import PropTypes from 'prop-types';

Questions.propTypes = {
	questions: PropTypes.array.isRequired,
	curIndex: PropTypes.number,
	setCurIndex: PropTypes.func.isRequired
};

function Questions(props) {
	const curIndex = parseInt(props.curIndex === undefined ? props.questions[0].id : props.curIndex);
	const question = props.questions.find(question => question.id === curIndex);
	return (
		<div className="questions">
			<Question question={question} setCurIndex={props.setCurIndex} />
		</div>
	);
}

export default Questions;
