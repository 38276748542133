import React from 'react';
import Questions from './components/Questions';
import './App.scss';

const questions = [
	{id: 1, content: 'Is this checkpoint driven by Business or non-functional requirements?', noGoTo: 2, yesGoTo: 9},
	{id: 2, content: 'Is this checkpoint about Visual Design? (not Content Authoring or UX Design)', noGoTo: 3, yesGoTo: 10},
	{id: 3, content: 'Is this checkpoint about Content Authoring? (not UX Design)', noGoTo: 4, yesGoTo: 11},
	{id: 4, content: 'Is this checkpoint about UX Design?', noGoTo: 5, yesGoTo: 12},
	{id: 5, content: 'Is this checkpoint about Implementation? (Development)', noGoTo: 6, yesGoTo: 13},
	{id: 6, content: 'Is this checkpoint about Testing?', noGoTo: 8, yesGoTo: 7},
	{id: 7, content: 'Is this checkpoint about running testing tools, or related to test protocols, or can the checkpoint be resolved directly and completely by a tester alone?', noGoTo: 8, yesGoTo: 14},
	{id: 8, content: 'Ownership is a Management concern.', noGoTo: null, yesGoTo: null},
	{id: 9, content: 'Ownership belongs to a Business Analyst.', noGoTo: null, yesGoTo: null},
	{id: 10, content: 'Ownership belongs to a Visual Designer.', noGoTo: null, yesGoTo: null},
	{id: 11, content: 'Ownership belongs to a Content Author.', noGoTo: null, yesGoTo: null},
	{id: 12, content: 'Ownership belongs to a User Experience Designer.', noGoTo: null, yesGoTo: null},
	{id: 13, content: 'Ownership belongs to a Front-End Developer.', noGoTo: null, yesGoTo: null},
	{id: 14, content: 'Ownership belongs to a Tester.', noGoTo: null, yesGoTo: null}
];

class App extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			curIndex: 1
		};

		this.handleChangeIndex = this.handleChangeIndex.bind(this);
	}

	render() {
		return (
			<div className="component-app">
				<Questions questions={questions} curIndex={this.state.curIndex} setCurIndex={this.handleChangeIndex} />
			</div>
		);
	}

	handleChangeIndex(index) {
		this.setCurIndex(index);
	}

	setCurIndex(index) {
		this.setState({
			curIndex: index
		});
	}
}

export default App;
