import React from 'react';
import PropTypes from 'prop-types';

Question.propTypes = {
	question: PropTypes.objectOf(PropTypes.shape({
		id: PropTypes.number.isRequired,
		content: PropTypes.string.isRequired,
		noGoTo: PropTypes.number,
		yesGoTo: PropTypes.number
	})).isRequired,
	setCurIndex: PropTypes.func.isRequired
};

function Question(props) {
	const question = props.question;
	return (
		<div className="component-question" id={"question-" + question.id}>
			<h2 className="text">
				{question.content}
			</h2>
			<div className="answers">
				{question.noGoTo ? <button className="no" onClick={() => props.setCurIndex(question.noGoTo)}>No</button> : null}
				{question.yesGoTo ? <button className="yes" onClick={() => props.setCurIndex(question.yesGoTo)}>Yes</button> : null}
				{!question.noGoTo && !question.yesGoTo ? <button className="restart" onClick={() => props.setCurIndex(1)}>Test again</button> : null}
			</div>
		</div>
	);
}

export default Question;
